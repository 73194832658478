import {acceptHMRUpdate, defineStore} from "pinia";
import {useClinics} from "~/store/clinics";
import {useApi} from "~/store/app/api";
import qs from 'qs';

export const useHome = defineStore("home", {
    state: () => {
        const config = useRuntimeConfig();
        const {clinic} = useClinics();

        return {
            apiBase: config.public.apiBase,
            address: null,
            coords: [],
            listZones: [],
            zoom: 10,
            zone: null,
            clinic,
        };
    },

    getters: {
        getZoneById: (state) => (zoneId) =>
            state.listZones.find((zone) => zone.id === zoneId),
        isCoordinateInsidePolygon: (state) => (coordinate, polygon) => {
            if (!(coordinate.length && polygon.length)) return false;
            // debugger;
            const x = coordinate[1];
            const y = coordinate[0];

            let inside = false;
            for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
                const xi = polygon[i].lon,
                    yi = polygon[i].lat;
                const xj = polygon[j].lon,
                    yj = polygon[j].lat;

                const intersect =
                    yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
                if (intersect) inside = !inside;
            }
            return inside;
        },
        zonePolygonByRegion: (state) => () => {
            // let listZones = state.listZones.filter((zone) => zone.attributes.regionId === regionId);
            let listZones = JSON.parse(JSON.stringify(state.listZones));

            if (listZones.length) {
                return listZones.map((zone) => {
                    return {
                        title: zone.attributes.title,
                        color: zone.attributes.color,
                        cost: zone.attributes.cost,
                        coords: [
                            [
                                ...zone.attributes.coordinates.map((coordinate) => [
                                    coordinate.lon,
                                    coordinate.lat,
                                ]),
                            ],
                        ],
                    };
                });
            }
            return [];
        },
        boundedCompile: (state) => (polygonList) => {
            if (polygonList) {
                polygonList = Object.values(polygonList).map(
                    (polygon) => polygon.coords[0]
                );

                let minLat = Infinity;
                let maxLat = -Infinity;
                let minLng = Infinity;
                let maxLng = -Infinity;

                for (let i = 0; i < polygonList.length; i++) {
                    const polygon = polygonList[i];
                    for (let j = 0; j < polygon.length; j++) {
                        // console.log(polygon[j]);
                        // const lat = Math.round(polygon[j][0] / 10) * 10;
                        // const lng = Math.round(polygon[j][1] / 10) * 10;

                        const lat = polygon[j][0];
                        const lng = polygon[j][1];

                        minLat = Math.min(minLat, lat);
                        maxLat = Math.max(maxLat, lat);
                        minLng = Math.min(minLng, lng);
                        maxLng = Math.max(maxLng, lng);
                    }
                }

                const bounds = [
                    [minLat, minLng],
                    [maxLat, maxLng],
                ];

                return bounds;
            }
            return false;
        },
        getRectangleVertices: (state) => (vertex1, vertex2) => {
            const lat1 = vertex1[0];
            const lng1 = vertex1[1];
            const lat2 = vertex2[0];
            const lng2 = vertex2[1];
            const minLat = Math.min(lat1, lat2);
            const maxLat = Math.max(lat1, lat2);
            const minLng = Math.min(lng1, lng2);
            const maxLng = Math.max(lng1, lng2);

            const rectangle = [
                [minLat, minLng],
                [minLat, maxLng],
                [maxLat, maxLng],
                [maxLat, minLng],
            ];

            return rectangle;
        },
        createApiUrl: (state) => (url) => {
            return `${state.apiBase}${url}`;
            // return `${state.apiBase}${url}${
            //     state.cache ? "" : "&" + new Date().getTime()
            // }`;
        },
    },
    actions: {
        async fetchZonesAdd(region) {
            const apiStore = useApi();
            `/api/zones?filters[clinic][region][$contains]=${region}&populate=deep,3`
            const populate = {
                fields: [
                    "color",
                    "title",
                    "cost",
                ],
                coordinates: '*',
                price: '*',
                docs: {
                    populate: {
                        fields: [
                            "fname",
                            "lname",
                            "pname",
                            "docId",
                            "consultation",
                            "clinics"
                        ]
                    },
                }
                //     populate: {
                //         zones: {
                //             populate: '*',
                //         },
                //         clinics: {
                //             fields: ['clinicId']
                //         }
                //     }
                // },
            };
            const filters = {
                clinic: {
                    region: {
                        '$contains': region,
                    }
                }
            }

            const query = qs.stringify({populate, filters, pagination: {pageSize: 1000}}, {encode: true});
            const url = `/api/zones?${query}`;
            const listZones = await apiStore.fetchData(url);

            if (listZones?.length) {
                function parseCoordinateText(text) {
                    const lines = text.split("\n");
                    return lines.map((line) => {
                        const coordinates = line.split(",");
                        return {
                            lat: parseFloat(coordinates[0]),
                            lon: parseFloat(coordinates[1]),
                        };
                    });
                }

                this.listZones = [...listZones].map((zone) => {
                    return {
                        ...zone,
                        attributes: {
                            ...zone.attributes,
                            coordinates: parseCoordinateText(zone.attributes.coordinates),
                        },
                    };
                });
            } else {
                console.log("List zones is empty");
            }
        },
        async fetchZones(region) {
            const listZones = await fetch(
                this.createApiUrl(
                    `/api/zones?filters[clinic][region][$contains]=${region}&populate=deep,3`
                )
            )
                .then((response) => response.json())
                .then((result) => result.data)
                .catch((error) => console.log("error", error));
            if (listZones?.length) {
                function parseCoordinateText(text) {
                    const lines = text.split("\n");
                    return lines.map((line) => {
                        const coordinates = line.split(",");
                        return {
                            lat: parseFloat(coordinates[0]),
                            lon: parseFloat(coordinates[1]),
                        };
                    });
                }

                this.listZones = [...listZones].map((zone) => {
                    return {
                        ...zone,
                        attributes: {
                            ...zone.attributes,
                            coordinates: parseCoordinateText(zone.attributes.coordinates),
                        },
                    };
                });
            } else {
                console.log("List zones is empty");
            }
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useHome, import.meta.hot));
}

import {acceptHMRUpdate, defineStore} from "pinia";
import {useApi} from "~/store/app/api";
import qs from 'qs';
import {useDocs} from "~/store/docs";

export const useGroups = defineStore("group", {
    state: () => {
        return {
            listGroups: [],
        };
    },
    getters: {
        getGroupByPriceId: (state) => (priceId) => {
            return state.listGroups.find((item) => item.attributes.priceId === priceId);
        },
        getGroupById: (state) => (groupId) => {
            return state.listGroups.find((item) => item.id === groupId);
        },
        getPricesByDocsIds: (state) => (listDocsId = []) => {
            const docStore = useDocs();
            const listPrices = [];
            listDocsId.forEach(docId => {
                const doc = docStore.getDocById(docId);
                if (doc?.attributes) {
                    doc?.attributes?.book?.forEach(book => {
                        book.pricetime.forEach((pricetime) => {
                            if (listPrices.indexOf(pricetime.price.data.id) === -1) {
                                listPrices.push(pricetime.price.data.id);
                            }
                        })
                    })
                }
                return {}
            });
            return listPrices;
        },
        getGroupsByPriceIds: (state) => (listPrices = []) => {
            const listGroups = [];

            state.listGroups.forEach((item) => {
                item.attributes.prices.data.forEach((price) => {
                    if (listPrices.indexOf(price.id) !== -1 && listGroups.indexOf(item.id) === -1) {
                        listGroups.push(item.id);
                    }
                })
            })

            return listGroups;
        },
        getGroupsByDocsIds: (state) => (listDocsId = [], online = null) => {
            const docStore = useDocs();
            const listPrices = [];
            const listGroups = [];
            listDocsId.forEach(docId => {
                const doc = docStore.getDocByMisId(docId);
                doc.attributes.book.Book.forEach(book => {
                    if (listPrices.indexOf(book.price.data.id) === -1) {

                        listPrices.push(book.price.data.id);
                    }
                });
            });

            state.listGroups.forEach((item) => {
                item.attributes.prices.data.forEach((price) => {
                    if (listPrices.indexOf(price.id) !== -1 && listGroups.indexOf(item.id) === -1) {
                        listGroups.push(item.id);
                    }
                })
            })

            return listGroups;
        }
    },
    actions: {
        async fetchGroups() {
            const apiStore = useApi();
            const filters = {}
            const populate = {
                prices: {
                    populate: '*',
                }
            }
            const cache = '&cache=get';
            const query = qs.stringify({filters, populate, pagination: {pageSize: 1000}}, {encode: true});
            const url = `/api/price-groups/?${query}${cache}`;
            this.listGroups = await apiStore.fetchData(url);
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useGroups, import.meta.hot));
}

export {createDateText, createDateTextWithWeekday, textDate, createTimeText, addMinutes};

function createDateText(date) {
    let dateResult = new Date(date);
    let months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря",];

    return dateResult.getDate() + " " + months[dateResult.getMonth()];
}

function createDateTextWithWeekday(date) {
    try {
        const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
        const weekdays = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];
        const dateString = typeof date === 'string' && date.includes(' ') ? date.split(' ')[0] : date;
        const dateResult = new Date(dateString);

        const day = dateResult.getDate();
        const month = months[dateResult.getMonth()];
        const weekday = weekdays[dateResult.getDay()];

        return `${day} ${month}, ${weekday}`;
    } catch (error) {
        console.error('Error creating date text with weekday:', error);
        return '';
    }
}

function textDate(date) {
    date = new Date(date);
    return (date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2));
}

function createTimeText(time) {
    if (time) {
        const begin = time.indexOf(" ") + 1;
        return time.slice(begin, begin + 5);
    }
}

function addMinutes(time, minutes) {
    const [hours, mins] = time.split(":").map(Number);
    const totalMinutes = hours * 60 + mins + +minutes;
    const newHours = Math.floor(totalMinutes / 60);
    const newMins = totalMinutes % 60;

    return `${newHours.toString().padStart(2, "0")}:${newMins
        .toString()
        .padStart(2, "0")}`;
}

function delMinutes(time, minutes) {
    const [hours, mins] = time.split(":").map(Number);
    const totalMinutes = hours * 60 + mins - +minutes;
    const newHours = Math.floor(totalMinutes / 60);
    const newMins = totalMinutes % 60;

    return `${newHours.toString().padStart(2, "0")}:${newMins
        .toString()
        .padStart(2, "0")}`;
}

import {acceptHMRUpdate, defineStore} from "pinia";
import {useClinics} from "~/store/clinics";

export const useEmail = defineStore("email", {
    state: () => {
        const config = useRuntimeConfig();
        return {
            apiBase: config.public.apiBase,
            region: null,
        };
    },

    getters: {},

    actions: {
        async send(type, payload) {
            const clinicStore = useClinics();

            if (!this.region) {
                this.region = clinicStore.getClinicById(clinicStore.clinicId).attributes.city;
            }

            if (payload?.birthday?.day) {
                payload.birthday = `${payload.birthday.year}-${
                    payload.birthday.month < 10
                        ? "0" + payload.birthday.month
                        : payload.birthday.month
                }-${
                    payload.birthday.day < 10
                        ? "0" + payload.birthday.day
                        : payload.birthday.day
                }`;
            }
            const url = new URLSearchParams({
                ...payload,
                type,
                region: this.region,
            }).toString();
            await $fetch("/api/mail?" + url);
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useEmail, import.meta.hot));
}

import {acceptHMRUpdate, defineStore} from "pinia";
import {usePatient} from "~/store/app/patient";
import {useApp} from "~/store/app/app";

export const useNav = defineStore("nav", {
    state: () => {
        return {
            step: 0,
            maxStep: 0,
            error: false,
            loader: true,
        };
    },
    getters: {},
    actions: {
        next() {
            if (this.step === 1) {
                const appStore = useApp();
                if (appStore.hasStart) {

                    appStore.docId = appStore.start.docId;
                    appStore.specialId = appStore.start.specialId;
                    appStore.specialId = appStore.start.specialId;

                        this.step =  4;
                    return;
                }
            }
            if (this.step === 3) {
                    this.toggleLoader(true);
            }
            this.step++;
        },
        prev() {
            if (this.checkPrev()) {
                this.error = false;
                if (this.step > 1)
                    this.step--;
            }
        },
        checkNext() {
            const appPatient = usePatient();

            switch (this.step) {
                case 1:
                    return appPatient.patient.age !== null;
                case 2:
                    break;
                case 3:
                    break;
                case 4:
                    return false;
                case 5:
                    return false;
            }
        },
        checkPrev() {
            return this.step > 1 && this.step < 7;
        },
        toggleLoader(payload = null) {
            if (payload === null) {
                this.loader = !this.loader;
                return;
            }

            this.loader = payload;
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useNav, import.meta.hot));
}

import {acceptHMRUpdate, defineStore} from "pinia";
import {useClinics} from "~/store/clinics";

export const useStat = defineStore("stat", {
    state: () => {
        const counterYm = 91740071;
        const callTouchConf = {
            ct_site_id: '57936',
            mod_id: '85ai74lm'
        }

        return {
            callTouchConf,
            counterYm,
        };
    },
    getters: {},
    actions: {
        goal(goal) {
            if (typeof ym === 'undefined') return
            switch (goal) {
                case 'start':
                    ym(91740071, 'reachGoal', 'app_v2_start')
                    break
                case 'success':
                    ym(91740071, 'reachGoal', 'app_v2_success')
                    break
                case 'error':
                    ym(91740071, 'reachGoal', 'app_v2_error')
                    break
            }
        },
        yandex(payload) {
            if (ym) {
                const type = "reachGoal";
                const action = payload.action + "_v1";
                ym(this.counterYm, type, action);
            }
            // window.dataLayer = window.dataLayer || [];
            // window.dataLayer.push({
            //     'event': 'formGTM'
            // });
        },
        callTouch(payload) {
            const clinicsStore = useClinics();
            const region = clinicsStore.clinicRegion;

            if (window.ct) {
                const sessionId = window.ct('calltracking_params', this.callTouchConf.mod_id)?.sessionId;
                if (sessionId) {
                    const ct_data = {
                        fio: payload.fio,
                        phoneNumber: payload.phone,
                        email: payload.email || '',
                        subject: payload.subject || 'Заявка',
                        tags: 'kids,' + region,
                        comment: '',
                        requestUrl: payload.requestUrl || 'https://kids.fomin-clinic.ru/',
                        sessionId
                    };

                    let _createPropsString = (obj) => {
                        let props = Object.assign(obj);
                        return Object.keys(props)
                            .reduce(function (a, k) {
                                a.push(k + "=" + encodeURIComponent(props[k]));
                                return a;
                            }, [])
                            .join("&");
                    };

                    const statStatus = fetch('https://api.calltouch.ru/calls-service/RestAPI/requests/' + this.callTouchConf.ct_site_id + '/register/', {
                        method: "POST",
                        headers: {"Content-Type": "application/x-www-form-urlencoded"},
                        body: _createPropsString(ct_data),
                    }).then((res) => res.json());
                }
            }
        }
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStat, import.meta.hot));
}
